/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const { initMode } = require("./src/helpers/mode");

exports.onClientEntry = () => {
  initMode(window);
};
